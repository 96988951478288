<template>
    <div id='leaflet-map' class='leaflet-map'></div>
</template>

<script>
import L from 'leaflet';
import axios from "axios";
import _ from 'lodash';
import 'leaflet-control-window';
import './L.Control.Window.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import airportData from '../assets/airports.json'

export default {
    data(){
        return{
            myMap: null,
            airports: airportData,
        }
    },
    mounted() {
        this.startLeafletGraph()
    },
    methods: {
        startLeafletGraph(){
            this.myMap = L.map('leaflet-map', {
                center: [39.8283,-98.5795],
                zoomControl: true,
                zoom:5,
                minZoom:3,
                zoomAnimation:false,
                fadeAnimation:true,
                markerZoomAnimation:true
            });     
            L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="https://carto.com/">carto.com</a> contributors'
            }).addTo(this.myMap);


            var masterAirportList = this.airports

            var dataset = {}

            let p = new Promise((resolve,reject) => {
                //Check Airtable Data to get verified status and cross reference masterAirportList
                var Airtable = require('airtable');
                var base = new Airtable({apiKey: 'patyBcggr4FfbCsND.24969954d9ed1237903de5b8fd2ea051ce3c5a2e0324cf8cbc904aecd5ed74ff'}).base('appxfkTkzsBquL1EU');
                base('vendor-list').select({
                    view: "Grid view"
                }).eachPage(function page(records, fetchNextPage) {
                    // This function (`page`) will get called for each page of records.
                    for (let record of records){
                        var airport_code = record.get("airport_code")
                        if (masterAirportList[airport_code] === undefined){
                            continue
                        }
                        else{
                        dataset[airport_code] = masterAirportList[airport_code]
                        if (dataset[airport_code]['verified'] === undefined){
                            dataset[airport_code]['verified'] = 0
                        }
                        if (dataset[airport_code]['unverified'] === undefined){
                            dataset[airport_code]['unverified'] = 0
                        }
                        if (record.get("verified_on") === undefined){
                            dataset[airport_code]['unverified'] = dataset[airport_code]['unverified'] + 1
                        }
                        else{
                            dataset[airport_code]['verified'] = dataset[airport_code]['verified'] + 1
                        }
                    }    
                }
                    // To fetch the next page of records, call `fetchNextPage`.
                    // If there are more records, `page` will get called again.
                    // If there are no more records, `done` will get called.
            
                    fetchNextPage();
                        
                }, function done(err) {
                    resolve("Success")
                    console.log("DONE")
                    if (err) { console.error(err); reject('Failed'); return; }
                });
            })

            var verifiedLayer = []
            var unverifiedLayer = []
            var allLayer = []

            p.then(()=>{
                createMarkers(this.myMap)
                addLayers(this.myMap)
                addSearch(this.myMap)
            }) 
            
            function createMarkers(myMap){
                const airportMarkerV = L.icon({
                    iconUrl: require('../assets/verifiedMarker.png'),
                    iconSize:     [37,49], 
                    iconAnchor: [18, 49],
                });
               
                const airportMarkerUV = L.icon({
                    iconUrl: require('../assets/unverifiedMarker.png'),
                    iconSize:     [45,55],
                    iconAnchor: [22, 55],
                });

                const airportMarkerBoth = L.icon({
                    iconUrl: require('../assets/bothMarkers.png'),
                    iconSize:     [45,55],
                    iconAnchor: [22, 55],
                });

                var container = L.DomUtil.create('div');

                for (var airport_code of Object.keys(dataset)) {
                    var airportData     = dataset[airport_code]
                    var verifiedCount   = airportData['verified']
                    var unverifiedCount = airportData['unverified']
                    var totalCount      = verifiedCount + unverifiedCount
                    var hasVerified     = verifiedCount != 0
                    var hasUnverified   = unverifiedCount != 0
                    var airportName     = airportData['name'] + " - " + airport_code
                    var airportLat      = parseFloat(airportData['latitude'])
                    var airportLong     = parseFloat(airportData['longitude'])
                    var vendorMessage   = ""
                    const getVendor     = "Get Vendor Info"
                    const reachOut      = "Reach Out"
                    const location = [airportLat, airportLong];

                    if (hasVerified && hasUnverified) {
                        vendorMessage = String(totalCount) + " total";
                        container = createContainer(myMap, airportName, totalCount, vendorMessage, getVendor, airport_code, airportData)
                        var markerB = L.marker(location , {icon: airportMarkerBoth}).bindPopup(container);
                        allLayer.push(markerB)
                    }

                    if (hasVerified) {
                        vendorMessage = String(verifiedCount) + " verified";
                        container = createContainer(myMap, airportName, totalCount, vendorMessage, reachOut, airport_code, airportData)
                        var markerV = L.marker(location , {icon: airportMarkerV}).bindPopup(container)
                        if (!hasUnverified){
                            allLayer.push(markerV)
                        }
                        verifiedLayer.push(markerV)
                    }

                    if (hasUnverified) {
                        vendorMessage = String(unverifiedCount) + " unverified";
                        container = createContainer(myMap, airportName, totalCount, vendorMessage, getVendor, airport_code, airportData)
                        var markerUV = L.marker(location , {icon: airportMarkerUV}).bindPopup(container)
                        if (!hasVerified){
                            allLayer.push(markerUV)
                        }
                        unverifiedLayer.push(markerUV)
                    }
                }
            }
            
            function createContainer(myMap, airportName, vendorCount, vendorMessage, buttonName, airport_code, airportData) {
                var container = L.DomUtil.create('div');
                var isAre = vendorCount == 1 ? 'is' : 'are'
                var addS  = vendorCount == 1 ? '' : 's'
                container.airportName = airportName
                container.innerHTML = "<h6 class='headerPopup'>" + airportName + "</h6>" +  "<h6 class='bodyPopup'> <br> There " + isAre + " " + vendorMessage + " vendor" + addS + " at this location" + "</h6>" + '<br/>'
                var reachoutButton = document.createElement('button');
                reachoutButton.className = 'btn btn-primary sidebar-open-button';
                reachoutButton.innerHTML = buttonName
                reachoutButton.onclick = emailModal.bind(this, airport_code, airportData, () => {
                    return myMap;
                })
                container.append(reachoutButton)
                return container
            }

            function addLayers(myMap){
                var vLayer = L.layerGroup(verifiedLayer)
                var uvLayer = L.layerGroup(unverifiedLayer)
                var aLayer = L.layerGroup(allLayer).addTo(myMap)        
            
                var overlayMaps = {
                    "<p class='toggle-title'>All Vendors</p>": aLayer,
                    "<p class='toggle-title'>Verified</p>": vLayer,
                    "<p class='toggle-title'>Non-verified</p>": uvLayer
                };
                
                var legend = L.control({position: 'topright'});

                legend.onAdd = function () {
                    var div = L.DomUtil.create('div');
                    div.innerHTML = "<h5 class='layer-title-bar'>Vendors</h5>";
                    return div;
                };
                legend.addTo(myMap);

                var ctrl = L.control.layers(overlayMaps, null,
                            {collapsed : true, position: 'topright'})
                            .addTo(myMap);

                var ctrlDiv = ctrl.getContainer();
                ctrlDiv
                    .querySelector('.leaflet-control-layers-list')
                    .insertBefore(legend.getContainer(),ctrlDiv.querySelector('.leaflet-control-layers-list').firstChild);
            }

            function addSearch(myMap){
                function sortAirports(a, b) {
                    var _a = a._popup._content.innerText;
                    var _b = b._popup._content.innerText;
                    if (_a < _b) {
                        return -1;
                    }
                    if (_a > _b) {
                        return 1;
                    }
                    return 0;
                }
                    
                L.Control.Search = L.Control.extend({
                    options: {
                        position: 'topright',
                        placeholder: 'Airport Search'
                    },

                    initialize: function (options /*{ data: {...}  }*/) {
                        // constructor
                        L.Util.setOptions(this, options);
                    },

                    onAdd: function () {
                        // happens after added to map
                        var container = L.DomUtil.create('div', 'search-container');
                        this.form = L.DomUtil.create('form', 'form', container);
                        var group = L.DomUtil.create('div', 'form-group', this.form);
                        this.input = L.DomUtil.create('input', 'form-control input-sm', group);
                        this.input.type = 'text';
                        this.input.placeholder = this.options.placeholder;
                        this.results = L.DomUtil.create('div', 'list-group', group);
                        L.DomEvent.addListener(this.input, 'keyup', _.debounce(this.keyup, 300), this);
                        L.DomEvent.addListener(this.form, 'submit', this.submit, this);
                        L.DomEvent.disableClickPropagation(container);
                        return container;
                    },

                    onRemove: function () {
                        // when removed
                        L.DomEvent.removeListener(this._input, 'keyup', this.keyup, this);
                        L.DomEvent.removeListener(this.form, 'submit', this.submit, this);
                    },

                    keyup: function(e) {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                        // do nothing
                        } 
                        else {
                            this.results.innerHTML = '';
                            if (this.input.value.length > 0) {
                                var value = this.input.value;
                                var results = _.take(_.filter(this.options.data, function(x) {
                                    return x._popup._content.airportName.toUpperCase().indexOf(value.toUpperCase()) > -1;
                                }).sort(sortAirports), 10);
                                _.map(results, function(x) {
                                    var listcontainer = L.DomUtil.create('a');
                                    listcontainer.innerHTML = x._popup._content.airportName;
                                    listcontainer.className="list-group-item"
                                    listcontainer.href="#"
                                    listcontainer.setAttribute('data-result-name', [x._latlng.lat,x._latlng.lng]);
                                    listcontainer.onclick = this.itemSelected
                                    this.results.append(listcontainer)
                                    return listcontainer;
                                }.bind(this));
                            }
                            var start = this.input.selectionStart;
                            var end = this.input.selectionEnd;
                            this.input.value = this.input.value.toUpperCase();
                            this.input.setSelectionRange(start,end);
                        }
                    },

                    itemSelected: function(e) {
                        L.DomEvent.preventDefault(e);
                        var elem = e.target;
                        var latCoordinate = elem.getAttribute('data-result-name').split(',')[0];
                        var longCoordinate = elem.getAttribute('data-result-name').split(',')[1];
                        myMap.flyTo([latCoordinate,longCoordinate],13);
                    }.bind(this),
                    
                    submit: function(e) {
                        L.DomEvent.preventDefault(e);
                    }
                });

                L.control.search = function(id, options) {
                    return new L.Control.Search(id, options);
                }

                L.control.search({
                    data: allLayer
                }).addTo(myMap)
            }

            function emailModal(airportCode,dataset, map,  e){
console.log('we have gotten this far');
                var myMap = map()
                var verification = ''
                console.log('this is our e', e)
                console.log('this is our airport code', airportCode)
console.log('dataset', dataset);
                let isTotal = dataset.verified > 0 && dataset.unverified > 0
                if (isTotal){
                    verification = "all"
                } else{
                    if (dataset.unverified > 0) {
                        verification = "unverified"
                    }
                    else if (dataset.verified > 0) {
                        verification = "verified"
                    }
                    else {
                        verification = "unknown"
                    }
                }
                // modal=true does not work so edited css of nomodal to replicate it in L.Control.Window.css
                var winOpts = L.control.window(myMap,{title:`<p class='modal-window-header'>Contact Vendor</p>`, content: `<p class='modal-window-body'>Enter your email for us to send you the vendor’s information.</p> <input type='email' id='email' name='email' class='email-input' placeholder='Email' value=''><br><br>`, visible: false, modal: false})                
                winOpts.prompt({action: confirmationModal.bind(this, () => {
console.log('this is our value', document.getElementById('email').value)
                        if (!document.getElementById('email').value.includes("@")){
                            alert("Must be a valid email address containing @ symbol"); window.location.reload(true)
                        } 
                        else{
                            const to_return = [myMap,document.getElementById('email').value,airportCode,verification];
                            return to_return;
                        }
                    }), 
                    buttonAction :`<h1 class='modal-button-email btn'>Email Me</h1>`, 
                    buttonCancel :`<h1 class='modal-button-cancel btn'>Cancel</h1>`, 
                    buttonOK : " "}).disableBtn()
                winOpts.show()
            }

            function confirmationModal(data){
                var info = data()
                console.log(info)
                var email = info[1]
                var myMap = info[0]
                console.log(email)
                var airportCode = info[2]
                var verification = info[3]    
                hitAPI(email,airportCode,verification)
                var winOpts = L.control.window(myMap,{title:`<p class='modal-window-header'>Submitted &#9992;&#65039;</p>`, content: `<p class='modal-window-confirmation-body'>Thank you for your interest. The vendor's information should be in your email shortly. If You do not see it in a bit check your spam folder or double check the email address is correct.</p>`, visible: false, modal: false})
                winOpts.prompt({callback: winOpts.hide, buttonOK: `<h1 class='modal-button-close btn'>Close</h1>`})
                winOpts.show()
            }   
            
            function hitAPI(emailVal,airportCodeVal,verificationVal) {
                console.log(emailVal)
                console.log(verificationVal)

                var timestampRaw = new Date().toLocaleString('en-US', {timeZone: 'America/New_York',})
                var timestampDate = timestampRaw.split(",")
                var timestampDateSections = timestampDate[0].split("/")
                var timestampEST = timestampDateSections[2] + "/" + timestampDateSections[1] + "/" + timestampDateSections[0] + ", " + timestampDate[1]
                console.log(timestampEST)

                var airport_code_label= "\"Airport_Code\""
                var airport_code= "\""+ airportCodeVal+ "\""

                var timestamp_label= "\"Timestamp\""
                var timestamp= "\""+ timestampEST+ "\""

                var email_label= "\"Email\""
                var email= "\""+ emailVal+ "\""

                var verified_label= "\"Verified\""
                var verified= "\""+ verificationVal+ "\""

                var data_label= "\"Data\""
                var data= "\"\""

                const article = {
                    "input": `{${email_label}: ${email},${timestamp_label}: ${timestamp},${airport_code_label}: ${airport_code},${verified_label}: ${verified},${data_label}: ${data}}`,
                    "stateMachineArn": "arn:aws:states:us-east-1:476162519802:stateMachine:vendormap-state-machine"
                };

                console.log(article)
                axios.post("https://l8z6merr6h.execute-api.us-east-1.amazonaws.com/dev/vendorinquiry", article)
                
                //.then(response => this.articleId = response.data.id)
                //.catch(error => {
                //  this.errorMessage = error.message;
                //  console.error("There was an error!", error);
                //});
            }
        }
   },  
}

</script>
<style>
    .bodyPopup {
        color: rgba(0, 0, 0, 0.66);
        font-size: 15px; 
        font-weight: 500; 
        margin-bottom: 16px;
        margin-top: -16px;
    }

    .btn{
        background: #4A6094; 
        border-radius: 2px; 
        box-sizing: border-box; 
        color: white;
        font-weight: 800;
        height: 49px; 
        margin-top: -20px; 
        position: relative; 
        width: 100%; 
    }

    .btn:hover {
        background: #0D2957; 
        color: white;
        transition: 0.2s;
    }

    .email-input{
        height: 50px;
        width:460px;
        text-indent: 15px;
    }

    .headerPopup {
        color: rgba(0, 0, 0, 0.66);
        font-weight: 600;
    }

    input[type="radio"]{
        -webkit-appearance:none;
        width:39px;
        height:20px;
        cursor:pointer;
        margin:0 1px;
        background-image: url('../assets/toggle-off.png'),-webkit-gradient(linear, left top, left bottom, from(white), to(#f2f2f2));
        vertical-align:bottom;
    }

    input[type="radio"]:checked{
        background-position:0px 0px;
        background-image: url('../assets/toggle-on.png'), -webkit-gradient(linear, left top, left bottom, from(white), to(#f2f2f2));
    }

    .layer-title-bar {
        background: #0D2957;
        border-radius: 3px 3px 0px 0px;
        color: white;
        height: 41px;
        line-height: 39px;
        position: relative;
        right:-21px;
        text-indent: 10px;
        top:-17px;
        width: 429px;    
    }

    .leaflet-control-layers-expanded {
        background: #F9F7F6;
        border-radius: 3px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
        height: 188px;
        position: absolute;
        width: 431px;
    }

    .modal-button-email {
        border-color: rgba(196, 196, 196, 1);
        bottom: 50px;
        color: white;
        left: 268px;
        margin-top: 11px;
        outline-color: rgba(196, 196, 196, 1);
        padding-top: 10px;
        width: 202px;
        top: -660px
    }
    
    .modal-button-cancel {
        background-color: white;
        border-color: rgba(196, 196, 196, 1);
        bottom: 50px;
        color: rgba(74, 96, 148, 1);
        margin-top: 11px;
        outline-color: rgba(196, 196, 196, 1);
        padding-top: 10px;
        right: -10px;
        width: 202px;
        top: -716px
    }

    .modal-button-close {
        border-color: rgba(196, 196, 196, 1);
        bottom: 50px;
        color: white;
        margin-top: 11px;
        outline-color: rgba(196, 196, 196, 1);
        padding-top: 10px;
        text-align: center;
        width: 475px;
        top: -623px;
        left: 3px;

    }

    .modal-window-body{
        color: rgba(0, 0, 0, 0.66);
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 30px;
        margin-left: -4px;
    }

    .modal-window-confirmation-body{
        color: rgba(0, 0, 0, 0.66);
        font-size: 17px;
        font-weight: 400;
        margin: 0 33px;
        text-align: left;
    }

    .modal-window-header{
        color: rgba(0, 0, 0, 0.66);
        font-size: 26px;
        font-weight: 600;
        margin-left: 30px;
        margin-top: 20px;
        position: relative;
    }

    .toggle-title {
        color:#0D2957;
        font-size:16px; 
        font-weight:500;
        line-height:19px; 
        margin-top: -20px; 
        text-indent: 50px;
    }
    
</style>







