<template>
  <div class="header">
    <div class="header-logo">
      <a href="https://www.airexpert.net/">
    <img
      src="./assets/img/aelogo.svg"
      class="logo"
      alt="AireXpert"
    >
</a>
    
    </div>
  

    <div class="wrap-collabsible">
  <input id="collapsible" class="toggle" type="checkbox">
  <label for="collapsible" class="lbl-toggle">

    <img
      src="./assets/img/burger.svg"
      class="burger"
      alt="AireXpert"
    >

  </label>
  <div class="collapsible-content">
    <div class="content-inner">

<div class="burger-nav navigation">
<ul>
<li class="first"><a href="https://www.airexpert.net/product">Product</a></li>
<li><a href="https://www.airexpert.net/solutions">Solutions</a></li>
<li><a href="https://www.airexpert.net/about">About</a></li>
<li><a href="https://www.airexpert.net/careers-airexpert">Careers</a></li>
<li><a href="https://www.airexpert.net/contact-us">Contact</a></li>
</ul>
<div class="Schedule">
  <a href="https://www.airexpert.net/request-a-demo" 
    class="btn btn-m btn-solid btn-primary text-white fill-white ml-4 tb:ml-12 hidden ds:block">
    Request a demo
    <img
      src="./assets/img/arrows.png"
      class="arrow"
      alt="AireXpert"
    >
  </a>
  </div>
</div>



    </div>
  </div>
</div>



<div class="navigation">
<ul>
<li class="first"><a href="https://www.airexpert.net/product">Product</a></li>
<li><a href="https://www.airexpert.net/solutions">Solutions</a></li>
<li><a href="https://www.airexpert.net/about">About</a></li>
<li><a href="https://www.airexpert.net/careers-airexpert">Careers</a></li>
<li><a href="https://www.airexpert.net/contact-us">Contact</a></li>
</ul>
<div class="Schedule">
  <a href="https://www.airexpert.net/request-a-demo" 
    class="btn btn-m btn-solid btn-primary text-white fill-white ml-4 tb:ml-12 hidden ds:block">
    Request a demo
    <img
      src="./assets/img/arrows.png"
      class="arrow"
      alt="AireXpert"
    >
  </a>
  </div>
</div>
</div>
<div class="title-box">
  <div class="title-header">
  <h1>Resource Map</h1>
  <p>Reaching new destinations, find your vendor ahead of time.</p>
  </div>


  </div>
  <div class="mapbody">
    <mapBox class="mapBox"/>

   
  </div>

  <div class="footer">
    <div class="footer-content">
       <div class="footer-content-container">
          
          
          
          <div class="footer-block1"> 
            <div> 
              <p>
    <img
      src="./assets/img/aelogo-white.svg"
      class="logo"
      alt="AireXpert"
    >
          </p>
          <p class="white">
            Connecting smart people to solve complex challenges.
 </p><p class="white">
AireXpert optimizes aircraft maintenance operations with real-time collaboration, event-driven data, and a single source of truth for technical, engineering, and management teams.
          </p>
          <div class="get-started">
  <a href="https://www.airexpert.net/request-a-demo" 
    class="btn btn-m btn-solid btn-primary text-primary btn-white fill-primary">
    Get started today
    <img
      src="./assets/img/arrows-white.png"
      class="arrow"
      alt="AireXpert"
    >
  </a>
  </div>
 </div>
        </div>

 <div class="footer-block2"> </div>

 <div class="footer-block3 white"> 
<div> 

  <h4 class="white">Company</h4>
  <div class="mini-menu">
    <ul role="menu">
  <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.airexpert.net/platform" role="menuitem" target="_self">Product</a></li>
  <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.airexpert.net/solutions" role="menuitem" target="_self">Solutions</a></li>
    <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.airexpert.net/about" role="menuitem" target="_self">About</a></li>
  <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.airexpert.net/careers" role="menuitem" target="_self">Careers</a></li>
 </ul>
  </div>
  
</div>
</div>






 <div class="footer-block4 white"> 
<div> 

  <h4 class="white">Contact</h4>
  <div class="mini-menu">
<ul role="menu">
  <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.airexpert.net/contact-us" role="menuitem" target="_self">Contact Us</a></li>
  <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.airexpert.net/request-a-demo" role="menuitem" target="_self">Request a Demo</a></li>
 </ul>
  </div>
</div>
</div>
  


 <div class="footer-block5 white"> 
<div> 

  <h4 class="white">Connect</h4>
  <div class="mini-menu">
<ul role="menu">
  <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://www.linkedin.com/company/airexpertdotnet/" role="menuitem" target="_blank"> 
        <img
      src="./assets/img/link.png"
      class="icons"
      alt="AireXpert"
    >
    LinkedIn
    </a>
  </li>
  <li class="hs-menu-item hs-menu-depth-1" role="none"><a href="https://twitter.com/airexpertdotnet?lang=en" role="menuitem" target="_blank">
          <img
      src="./assets/img/twitter.png"
      class="icons"
      alt="AireXpert"
    >
  Twitter</a></li>
 </ul>
  </div>
</div>





 </div>


      </div>

    </div>

<div class="lower-footer">
  <div class="lower-footer-content">
        <div class="lower-left">
 © {{ copyrightYear }} AireXpert, All rights reserved. Various trademarks held by their respective owners.
    </div>
        <div class="lower-right">
      <span><a href="https://statuspage.incident.io/airexpert" role="menuitem" target="_self">System Health Status</a></span>
      <span><a href="https://www.airexpert.net/privacy" role="menuitem" target="_self">Privacy Policy</a></span>
   </div>
   </div>


 </div>

  </div>
</template>

<script>
import mapBox from './components/mapBox.vue'
export default {
  name: 'App',
  components: {
    mapBox
  },
  data() {
    return {
      copyrightYear: this.getCopyRightYear()
    }
  },
  methods: {
    getCopyRightYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400&display=swap');

html, body {
  font-family: 'Nunito Sans';
}

#app {
  font-family: 'Nunito Sans';
}

.leaflet-right .search-container {
  position: absolute;
  right: 0;
  top: 55px;
  min-width: 200px;
  z-index: 1;
  width: max-content;
}

.leaflet-right .leaflet-control-layers.leaflet-control {
  z-index: 1000;
  float: right;
}

.leaflet-right .leaflet-control-layers-expanded {
  z-index: 2222 !important;
  right: 0 !important;
}

.navigation ul, .header-logo, .navigation {
  display: inline-flex;
}
.navigation ul {
    margin-right: 30px;
    font-size: 16.6px;
    font-family: 'Nunito Sans';
    font-weight: 800;
}

.Schedule a {
    padding-top: 14px !important;
    font-size: 16.8px !important;
}

.burger {
  width: 20px;
filter: brightness(0) saturate(100%) invert(19%) sepia(29%) saturate(6451%) hue-rotate(221deg) brightness(81%) contrast(86%);
}

.header-logo {
padding-top: 8px;
}

.nonmodal .control-window {
      max-width: 600px;
    transform: none !important;
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    top: calc(50% - 125px);
}

.get-started {
  margin-top: 30px;
}

.navigation {
      padding-top: 1rem;
    padding-bottom: 1rem;
}


h1 {
  color: #243C93;
  font-size: 66px;
  font-weight: 900;
  font-family: canada-type-gibson,sans-serif;
}

.btn-white {
  background: #fff !important;
  color: #243C93 !important;
}

p {
  color: #000;
  font-size: 20px;
  font-weight: 300;
}

.logo {
  height: 45px;
}

.navigation ul {
  list-style: none;
  font-size: 16px;
  color: #243C93;
  font-weight: 800 !important;
}

  .navigation ul li {
    padding:0 16px;
  }

  .navigation ul li a {
    color: #243C93;
    text-decoration: none;
    font-weight: 800;
  }

  .first {
    padding-left: 0;
  }

.header {
    width: 100%;
    height: 89px;
    color: #000;
    background: #fff;
    display: flex;
    float: none;
        justify-content: space-between;
            align-items: stretch;
    width: 100%;
    top: 0px;
    padding: 16px 60px;
    position: relative;
    z-index: 999;
}
.active {
    color: #fff;
    border-bottom: 3px solid #f2a802;
    padding-bottom: 24px;
}
.mapBox {
position: relative;
    top: -255px;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 90%;
    height: 612px;
}

.title-box {
background: rgb(255,255,255);
height: 495px;
}

.title-header {
  width: 90%;
  margin: 0 auto;
  padding-top: 80px;
}

.btn.btn-solid.btn-primary, .btn-cta.btn-solid.btn-primary a {
    background-color: #243C93;
        height: 51px;
    padding-top: 12px;
    border: none;
    border-radius: 32px;
    width: 204px;
    font-weight: 400;
    top: 6px;
}

.btn.text-white, .btn-cta.text-white a {
    color: #fff !important;
}

.btn.btn-m, .btn-cta.btn-m a, .hs-button {
    font-size: 1rem;
}

.ds\:block {
    display: block;
}

.tb\:ml-12 {
    margin-left: 3rem;
}


.Schedule {
  display: flex;
}

.footer {
  width: 100%;
  background-color: #0e183b;
}

.footer-content {
  position: relative;
  padding-bottom: 5rem;
  padding-top: 5rem;
      padding-left: 60px;
    padding-right: 60px;
    max-width: 1560px;
}
.lower-footer {
border-top: 1px solid #fff;
      margin-left: 60px;
    margin-right: 60px;
}
    

.lower-footer-content {
  position: relative;
  padding-bottom: 3rem;
  padding-top: 2rem;
    max-width: 1560px;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.lower-right span {
  padding-left: 20px;
}

.lower-right span a {
  color:#fff;
  text-decoration: underline;
}

.footer-content-container {
  display: flex;
      flex-wrap: wrap;

}


.footer-block1 {
  width: 43.333333%;
      text-align: left;
          padding-left: 1rem;
    padding-right: 1rem
}

.footer-block2  {
      width: 6.666667%;
      text-align: left;
      padding-left: 1rem;
    padding-right: 1rem;
}

.footer-block3, .footer-block4 , .footer-block5 {
      width: 16.666667%;
      text-align: left;
      padding-left: 1rem;
    padding-right: 1rem;
}


.white {
  color: #fff;
}

.mini-menu ul {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    flex-direction: column;
    color: #fff;
    list-style: none;
}

.mini-menu ul li {
  padding-top: 12px;
}

.mini-menu ul li a {
  color: #fff;
}

.logo2 {
  height: 55px;
}

.wrap-collabsible {
  display: none;
}

.footer p {
  font-size: 16px;
  font-weight: bold;
}

.footer a {
  text-decoration: none;
}

h4 {
  font-size: 20px;
  font-family: canada-type-gibson,sans-serif;
  font-weight: 600;
}
.icon {
    margin: -0.25rem;
        align-items: center;
            display: inline-flex;
                width: 2rem;
    height: 2rem;
            
}

a svg {
      width: 18px;
    margin: 0 auto;
}

.bg-primary-500 {
    background-color: #e8ab3b;
    border-radius: 4px;
}

.icons {
  margin-right: 10px;
}


@media (max-width: 995px) {
.header {
    margin: 0 auto;
    width: 100%;
}

.burger {
  position: relative;
  right: 5%;
}



.leaflet-control-window .promptButtons  {
  width: 90%;
   margin: 0 auto;
   padding: 8px;
}

.modal-button-cancel {
  top: -685px;
      right: 0px;
    width: 100%;
}

.leaflet-control-window button {
  padding: 0;
    margin: 20px 0 20px 0;
}

.leaflet-control {
  width: 90%;
}

.leaflet-control-window .promptButtons button {
  width: 100%;
}

.modal-button-email {
  left: 0;
  width: 100%;
}

.control-window {
  height: 360px;
}

.email-input {
  width: 90%;
}

.lower-footer {
border-top: 1px solid #fff;
      margin-left: 25px;
    margin-right: 25px;
}

.header-logo {
  position: absolute;
  left: 5%;
}

 .footer-block1 {
  width: 100%;
 }

 .navigation {
  display: none;
}

 .btn.btn-solid.btn-primary, .btn-cta.btn-solid.btn-primary a {
    margin-bottom: 40px;
}

.footer-block2 {
  width: 0%;
  padding-left: 0;
  padding-right: 0;
}

.footer-block3, .footer-block4, .footer-block5 {
    width: 32.666667%;
}

.lower-footer-content {
    flex-direction: column-reverse;
}

.lower-right {
  padding-bottom: 40px;
}

.lower-right span {
padding-left: 0;
        display: flex;
        padding-bottom: 12px;
}

.footer-content {
    position: relative;
    padding-bottom: 5rem;
    padding-top: 5rem;
    padding-left: 12px;
    padding-right: 12px;
    max-width: 1560px;
}

h1 {
    font-size: 44px;
}

.wrap-collabsible {
  display: initial;
        width: 100%;
        position: absolute;
        right: 0;
        z-index: 111111;
        padding-left: 0;


}

}


input[type='checkbox'] {
  display: none;
}


.lbl-toggle {
  display: block;

  font-weight: bold;
  font-family: monospace;
  font-size: 1.2rem;
  text-transform: uppercase;
      text-align: right;
    padding: 1rem;
    background: transparent;
padding-right: 0;
  cursor: pointer;

  border-radius: 7px;
  transition: all 0.25s ease-out;
}

.lbl-toggle:hover {
  color: #7C5A0B;
}

.collapsible-content {
  max-height: 0px;
  overflow: hidden;
  background-color: #fff;

  transition: max-height .25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: 100vh;
}

.toggle:checked + .lbl-toggle::before {
  transform: rotate(90deg) translateX(-3px);
}

.toggle:checked + .lbl-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.burger-nav {
  width: 100%;
  display: inline;
}

.burger-nav ul {
  display: inline;
}

.burger-nav ul li {
    padding: 16px 16px 0;
}

.burger-nav ul li {
    padding: 16px 16px 0;
}

.burger-nav .btn.btn-solid.btn-primary,.burger-nav .btn-cta.btn-solid.btn-primary a {
  margin-left: 12px;
  margin-top: 20px;
}

.header {
  z-index: 11;
}

.mapbody {
  position: relative;
  z-index: 10;
}

.content-inner {
  padding-left: 50px;
}

</style>
